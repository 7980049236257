.process-main {
    font-family: 'Source Sans Pro';
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .new-process-btn {
    padding: 12px;
    color: white;
    font-weight: 600;
    border: none;
    background: #035642;
    border-radius: 8px;
  }
  
  .new-process-btn:hover {
    opacity: 0.9;
  }
  
  .process-line {
    width: 90%;
    height: 2px;
    background-color: grey;
  }
  
  .process-section-center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  
  .process-section-left {
    width: 100%;
    display: flex;
    align-items: left;
    justify-content: left;
    padding: 16px;
    position: sticky;
    top: 0;
    align-self: flex-start;
  }
  
  .process-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #097252;
  }
  
  .red {
    color: #ff0000;
    background: rgba(255, 0, 0, 0.2);
    border: 1px solid rgba(255, 0, 0, 0.6);
  }
  
  .process-new-block-container {
    display: flex;
  }
  
  .new-block-modal {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .new-block-modal-content {
    background-color: #fefefe;
    padding: 20px;
    border: 1px solid #888;
    width: 90%; 
    height: 90%;
    overflow: auto;
    justify-content: flex-start;
  }
  
  .process-input-title {
    color: #3c4852;
    font-weight: 600;
  }
  
  .process-input-text {
    width: 100%;
    font-size: 600;
  }
  
  .process-input-textarea {
    width: 100%;
    font-size: 600;
    color: white;
    background-color: black;
    height: 30vh;
  }
  
  .process-block {
    display: flex;
    justify-content: left;
    width: 90%;
    background-color: lightgrey;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 16px;
    padding: 8px;
    border-radius: 4px;
    margin-left: 8px;
    cursor: pointer;
  }
  