$small: 500px;
$medium: 800px;

.rjsf {
  font-family: 'Source Sans Pro';
  font-style: normal;
  position: sticky;
  top: 0;
  align-self: flex-start;

  &.small-desc {
    .field-description {
      font-weight: 400;
      font-size: 16px;
    }

    .checkbox {
      margin-left: 6px;

      @media screen and (max-width: $small) {
        margin-left: 3px;
      }
    }
  }

  .required {
    padding-left: 4px;
  }

  .error-detail {
    display: none;
  }

  legend {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    align-items: center;
    color: #3c4852;
    padding: 8px 12px;
  }

  .field-description {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
    color: #3c4852;
    padding: 8px 12px;
    margin-bottom: 0px;
    @media screen and (max-width: $small) {
      padding: 4px 4px;
    }
  }

  .field-string,
  .field-number {
    display: inline-block;
    padding: 12px;
    width: 100%;

    @media screen and (max-width: $small) {
      padding: 4px;
    }

    label {
      border-radius: 0px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #3c485280;
      border: 1px solid rgba(33, 42, 57, 0.25);
      border-bottom-width: 0px;
      width: 100%;

      padding: 4px 16px 0px 16px;
      margin-bottom: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    input,
    select {
      border-radius: 0px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: #3c4852;
      border: 1.5px solid rgba(33, 42, 57, 0.25);
      border-top-width: 0px;
      width: 100%;

      padding: 0px 16px 4px 16px;
    }

    select {
      appearance: none;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      background-image: linear-gradient(45deg, transparent 50%, #3c4852 50%),
        linear-gradient(135deg, #3c4852 50%, transparent 50%);
      background-position: calc(100% - 10px) calc(1em + 2px),
        calc(100% - 5px) calc(1em + 2px), calc(100% - 1.5em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 1.5em;
      background-repeat: no-repeat;
    }

    select:active {
      background-image: linear-gradient(45deg, #3c4852 50%, transparent 50%),
        linear-gradient(135deg, transparent 50%, #3c4852 50%);
      background-position: calc(100% - 5px) 1em, calc(100% - 10px) 1em,
        calc(100% - 1.5em) 0.5em;
    }

    input:focus,
    select:focus,
    textarea:focus {
      box-shadow: none;
    }
  }

  .checkbox {
    margin-left: 14px;

    @media screen and (max-width: $small) {
      margin-left: 3px;
    }

    input {
      margin-right: 12px;

      @media screen and (max-width: $small) {
        padding: 4px;
      }
    }
  }

  .slot {
    label {
      cursor: pointer;
      margin: 12px 12px;

      input {
        display: none;
      }

      span {
        background: #ffffff;
        border: 1px solid rgba(60, 72, 82, 0.5);
        border-radius: 3px;
        box-sizing: border-box;

        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #3c4852;

        padding: 8px 12px;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      input:checked + span {
        background: #397788;
        color: #ffffff;
      }
    }
  }

  .drop-area {
    label {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #3c4852;
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
      border: 0px;
      padding: 0px 0px 16px 0px;
    }

    .fileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      color: #3c4852;
    }

    p {
      position: relative;
      cursor: pointer;
    }

    p::after {
      content: 'Upload or drag and drop files here';
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      height: 64px;
      display: flex;
      align-items: center;
      color: #097252;
      background: rgba(9, 114, 82, 0.1);
      border: 1px dashed rgba(60, 72, 82, 0.4);
      border-radius: 4px;
      padding: 12px 16px;
    }

    input {
      border: 0px;
      opacity: 0;
      position: absolute;
      width: fit-content;
      height: 100%;
      cursor: pointer;
    }
  }

  .textarea {
    textarea {
      border-radius: 4px;
      color: #3c4852;
      border: 1.5px solid rgba(33, 42, 57, 0.25);
      width: 100%;
      padding: 8px;
    }

    label {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #3c4852;
      white-space: unset;
      overflow: unset;
      text-overflow: unset;
      border: 0px;
      padding: 0px 0px 16px 0px;
    }
  }

  .w-50 {
    width: 50%;

    @media screen and (max-width: $medium) {
      width: 100% !important;
    }
  }

  .w-30 {
    width: 30%;

    @media screen and (max-width: $medium) {
      width: 60% !important;
    }
  }

  .w-20 {
    width: 20%;

    @media screen and (max-width: $medium) {
      width: 40% !important;
    }
  }
}
