.scheduleManagementSass{

  .main-body {
      margin-left: 250px;
      min-height: 100%;
  }

  .as-dl-sidebar {
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      width: 250px;
      background-color: #055646;
      color: var(--white-color);
      overflow-x: hidden;
      z-index: 1;
    }

    /* General sidebar styles */
    .as-dl-sidebar-lg.link img {
      margin-bottom: 20px;
    }
    a{
      color: #055646;
    }
    .as-dl-sidebar ul {
      list-style: none;
      padding: 0;
    }
    
    .as-dl-sidebar li {
      margin-bottom: 10px;
    }
    
    /* NavLink styles */
    .nav-link {
      text-decoration: none;
      color:white;
      padding: 10px 25px;
      display: block;
      position: relative;
    }
    .nav-link:hover {
      color: white;
    }

    
    /* Active NavLink styles */
    .active-link {
      font-weight: bold;
      background-color: #022A22;
      ;
    }
    
    /* White bar on the left side of the active link */
    .active-link::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 5px;
      background-color: white;
    }



    .navigationBar{
      background-color: #212A391A;
      margin: 25px 20px;
      padding: 10px; 
    }

    .row{
      margin: 0 !important;
    }

    .addButton{
      background-color: #065546;
      margin-left: auto;
      color:white;
    }
    .addButton:hover{
      color: white;
    }


    .modal-dialog.modal-sm{
      width: 600px;
      /* margin-top: 100px; */
    }
    .modal-dialog.modal-sm.addFacultyModal{
      /* margin-top: 30px; */
      width: 600px;
    }
    .textfields.addFaculty{
      margin-bottom: 10px;
    }

    .addFacultyModal>.modal-content>.modal-body{
      max-height: 600px;
      overflow-y: scroll;
    }
    .modalFormRow{
      margin-bottom: 15px;
    }

    .classActionButton{
      cursor: pointer;
    }


    .table td.fit, 
  .table th.fit {
    white-space: nowrap;
    width: 1%;
  }

  .action-icons {
  //   display: flex;
  //   align-items: center;
  //   gap: 8px; /* Adjust the spacing between icons as needed */
    display: flex;
    flex-direction: row; /* Images will be inline (default flex direction) */
    align-items: center; /* Vertically center the images */
    justify-content: center; /* Center the icons horizontally (optional) */
    gap: 8px; /* Adjust the spacing between the icons */
    height: 100%; /* Ensure the container takes up full height of the td */
  }

  .classActionButton {
    cursor: pointer;
    width: 32px; /* Adjust the icon size as needed */
    height: 32px; /* Adjust the icon size as needed */
  }


  .progressBarLiveLecture > ul, .progressBarFacLiveLecture > ul{
    height: 45px;
    display: flex;
    justify-content: flex-start;
    text-align: center;
    align-items: center;
    padding-left: 0px;
  }

  .progressBarLiveLecture > ul > li{
    display: flex;
    width: 25%;
    align-content: center;
    justify-content: center;
    height: 100%;
  }

  .progressBarFacLiveLecture > ul > li {
    display: flex;
    width: 33.33%;
    align-content: center;
    justify-content: center;
    height: 100%;
  }

  .progressBarLiveLecture > ul > li > a, .progressBarFacLiveLecture > ul > li > a{
    vertical-align: middle;
    display: flex; /* Add this line */
    align-items: center; /* Add this line */
    justify-content: center;
    text-decoration: none;
    color: inherit;
  }
  .activeLi{
    background: #065546;
    border-radius: 10px;
    /* width: 25%;
    height: 100%;
    text-align: center;
    text-decoration: none; */
  }
  .activeLi > li{
    color: white !important;
    font-weight: bolder;
  }

  .nav-ul-li{
    width: 25%;
    height: 100%;
    text-align: center;
    text-decoration: none;
    color: black;
  }
  .nav-ul-li:hover{
    text-decoration: none;
    color: black;
  }
  .nav-ul-li > li{
    text-align: center;
    vertical-align: middle;
    text-decoration: none;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  .lectureMetricsDiv{
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
  }
  .addCol2{
    padding: 0px !important;
  }
  .lectureMetricsCard{
    padding: 8px 10px;
    border: 1px solid #05896a;
    border-radius: 15px;
    /* box-shadow: 0px 0px 16px 0px rgba(3, 86, 66, 0.25); */
    margin-right: 16px;
  }
  .lectureMetricsCard:last-child{
    margin-right: 0;
  }
  .lectureMetricsCard > .green {
    color: #097252;
  }
  .lectureMetricsCard > .redh3 {
    color: #DC3545;
  }
  .lectureMetricsP{
    height: 26px;
    margin-bottom: 5px;
    font-size: 15px;
  }
  .lectureMetricsH3{
    font-size: 32px;
  }
  .draftMainDiv{
    margin-top: 30px;
  }

  .lectureTableSearch{
    width: 100%;
  }

  .lectureTable{
    text-align: center;
    margin-top: 10px;
    border-collapse: collapse;
  }
  .lectureTable, 
  .lectureTable > thead,
  .lectureTable > tbody,
  .lectureTable > thead > tr,
  .lectureTable > thead > tr > th ,
  .lectureTable > tbody > tr,
  .lectureTable > tbody > tr > td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .lectureTable > tbody > tr > td {
    font-weight: 500;
  }

  .lectureTable > thead > tr > th {
    font-weight: bold;
  }

  .action-icons{
    border: 0px !important;
  }
  .lectureTable > thead > tr > th, .lectureTable > tbody > tr > td{
    white-space: nowrap;
  }
  .LiveLecName{
    max-width: 200px;
    white-space: wrap !important;
  }
  .col8,.col4{
    padding: 0 !important;
  }

  .yellowRows{
    background-color: #fdf2d0;
  }

  .classRoomSchedule{
    margin-top: 150px !important;
  }

  .bs-popover-bottom>.arrow::before, .bs-popover-bottom>.arrow::after{
    left: 61px
  }
  .popover.show.bs-popover-bottom{
    left: -61px !important;
    max-width: 320px;
  }
  .popover-inner{
    width: 318px;
  }
  .myPopoverBtn{
    background-color: grey;
    color: white;
    padding: 4px 11px;
    width:25px;
    height:25px;
    border-radius: 25px;
  }
  .css-ub1z3a-MuiFormLabel-asterisk{
    color: red;
  }
  .MuiFormLabel-asterisk{
    color: red !important
  }

  .schedManagementGreenButton{
    background-color: #065546;

  }

  .addedsuccessModal > .modal-content{
    width: 70%;
    border-radius: 8px;
  }

  .addedsuccessModal > .modal-content{
    margin: auto !important;
  }
  .backdropClassName{
    overflow-y: hidden !important;
  }

  .markCompleteModal > .modal-content{
    width: 30%;
    border-radius: 8px;
    margin: auto;
    /* margin-top: 20%; */
  }

  .cancelModal > .modal-content{
    width: 40%;
    border-radius: 8px;
    margin: auto;
    margin-top: 20%;
  }

  .changeMuiColor > li > .MuiAutocomplete-root:first-child{
    margin-top: 80px;
  }
  .changeMuiColor > li > .MuiAutocomplete-root {
    background: #055646;
    margin: 24px auto;
    min-width: 95%;
  }

  .changeMuiColor .MuiInputLabel-root,
  .changeMuiColor .MuiSvgIcon-root,
  .changeMuiColor .MuiInputBase-root {
    color: white !important;
  }

  .changeMuiColor .MuiInputLabel-root.Mui-focused,
  .changeMuiColor .MuiInputBase-root.Mui-focused,
  .changeMuiColor .MuiOutlinedInput-notchedOutline.Mui-focused {
    color: white !important;
  }

  .changeMuiColor  .MuiOutlinedInput-notchedOutline {
    border-color: white !important;
  }

  .changeMuiColor .MuiFormLabel-root.Mui-focused{
    color: white !important;
  }
  .changeMuiColor .MuiOutlinedInput-notchedOutline.Mui-focused {
    border-color: white !important;
  }

  .addLiveCol{
    padding: 0px !important;
  }

  /* .MuiFormControl-root {
    border: 1px solid #ced4db;
    padding: 0px 10px;
    border-radius: 4px;
  }
  .MuiFormControl-root .MuiFormControl-fullWidth {
    border: 0;
  }

  .MuiFormLabel-root 
  .MuiInputLabel-root 
  .MuiInputLabel-formControl 
  .MuiInputLabel-animated 
  .MuiInputLabel-sizeMedium 
  .MuiInputLabel-standard 
  .MuiFormLabel-colorPrimary 
  .MuiInputLabel-root 
  .MuiInputLabel-formControl 
  .MuiInputLabel-animated 
  .MuiInputLabel-sizeMedium 
  .MuiInputLabel-standard, 
  .MuiFormLabel-root 
  .MuiInputLabel-root 
  .MuiInputLabel-formControl 
  .MuiInputLabel-animated 
  .MuiInputLabel-sizeMedium 
  .MuiInputLabel-standard 
  .MuiFormLabel-colorPrimary 
  .MuiInputLabel-root 
  .MuiInputLabel-formControl 
  .MuiInputLabel-animated 
  .MuiInputLabel-sizeMedium 
  .MuiInputLabel-standard
  {
    padding-left: 15px;
  } */

  .classRoomSchedule > .modal-content > .modal-body{
    padding: 20px 0;  
  }
  .facultyInvoicePopover{
    min-width: 320px;
  }


  .attendance__LectureDetails{
    border-radius: 16px;
    border: 1px solid rgba(3, 86, 66, 0.50);
    background: rgba(3, 86, 66, 0.08);
    padding: 9px 10px;
    height: fit-content;
    max-height: 105px;
  }

  .learners__metrics > .lectureMetricsDiv{
    height: 82px;
    margin-top: 0px;
  }

  .attendance__contentText{
    color: #055646;
  }

  .learnersAttendanceTable, .learnersAttendanceTable > tbody > tr > td, .learnersAttendanceTable > thead > tr > th{
    border-collapse: collapse;
    border: 1px solid black !important;
    padding: 7px;
  }
  .learnersAttendanceTable > thead > tr{
  background: #efefef;
  }

  .checkedRow,.checkedRow>td{
    background-color: #05564626;
    border: 1px solid black !important;
  }

  .width30{
    width: 30% !important;
  }

  .presentTd{
    background-color: #dce9d5;
  }
  .absentTd{
    background-color: #eecdcd;
  }

  .facultyDashboardTable > thead > tr {
    background-color: #d9d9d9;
    white-space: nowrap;
    text-align: center;
  }
  .facultyDashboardTable > thead > tr,
  .facultyDashboardTable > tbody > tr,
  .facultyDashboardTable > thead > tr > th,
  .facultyDashboardTable > tbody > tr > td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  .greyColoredBg{
    background-color: #efefef;
  }

  .modalMessage.sucess{
    color: green;
  }
  .modalMessage.failed{
    color: red;
  }
  .modal-sm, .modal-sm-sm{
    width: 400px !important;
    max-width: 400px !important;
  }
  .modal-dialog .width30 .modal-dialog-centered{
    width: 400px !important;
  }

  @media (min-width: 576px) {
    .modal-sm {
        width: 400px !important;
        max-width: 400px !important;
    }
    .modal-dialog.width30.modal-dialog-centered{
      width: 400px !important;
    }
    .modal-dialog .cancelModal .modal-sm .modal-dialog-centered{
      width: 400px !important;
      max-width: 400px !important;
    }
  }
}