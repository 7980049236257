.courseHeader {
    .home-dashboard-header .fixed-header {
      left: 250px;
      justify-content: unset;
    }
    .MuiTypography-root {
      margin: auto;
    }
    @media (max-width: 910px) {
      .home-dashboard-header .fixed-header {
        // left: 250px;
        justify-content: center;
      }
      .breadcrumbs {
        display: none;
      }
    }
    .MuiBreadcrumbs-li a {
      color: var(--baseColor);
    }
    .MuiBreadcrumbs-li a:hover {
      color: var(--btnSelectedStateColor);
      font-weight: 500;
    }
    .header-content .MuiBreadcrumbs-root {
      // margin-left: 1rem;
      margin-left: 10%;
    }

    .fixed-gdtl-header{
      border-radius: 4px;
      background: rgba(33, 42, 57, 0.10); 
    }
    .MuiBreadcrumbs-li p {
      font-family: Source Sans Pro;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
  
      color: rgba(0, 0, 0, 0.5);
    }
    .header-heading2 {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      font-family: "Source Sans Pro", sans-serif;
      overflow: hidden;
      color: #055646;
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 
      margin-left: 10px;
    }

    .header-content {
      height: 40px; 
      flex-shrink: 0; 
    }
    .heading-text2 {
      width: 100%;
      overflow: hidden;
      color: #055646;
      text-overflow: ellipsis; 
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 
    }
    .heading-text3 {
      overflow: hidden;
      color: rgba(33, 42, 57, 0.75);
      text-overflow: ellipsis;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal; 
    }
    .nav-item.header-get-help-container {
      min-width: 110px;
    }
    @media only screen and (max-width: 910px) {
      .heading-text2,
      .header-heading2 {
        display: none;
      }
    }
  }
  