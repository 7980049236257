.dropdown {
  width: 200px;
  margin: auto;
  margin-top: 20px;
  /* border: '2px',solid white; */
}

.dropdown .MuiAutocomplete-root {
  background: #055646;
  margin: 60px auto;
  min-width: 95%;
}

.dropdown .MuiInputLabel-root,
.dropdown .MuiSvgIcon-root,
.dropdown .MuiInputBase-root {
  color: white !important;
}

.dropdown .MuiInputLabel-root.Mui-focused,
.dropdown .MuiInputBase-root.Mui-focused,
.dropdown .MuiOutlinedInput-notchedOutline.Mui-focused {
  color: white !important;
}

.dropdown .MuiOutlinedInput-notchedOutline {
  border-color: white !important;
}

.dropdown .MuiOutlinedInput-notchedOutline.Mui-focused {
  border-color: white !important;
}

.ui.green.button {
  float: right !important;
}

.modal-body-done {
  height: 424px !important;
  width: 480px !important;
  border-radius: 8px !important;
}


.dropdown .MuiInputLabel-root.Mui-disabled,
.dropdown .Mui-disabled .MuiSvgIcon-root,
.dropdown .MuiInputBase-root.Mui-disabled {
  color: rgb(165, 165, 165) !important;
}

.dropdown .Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: rgb(165, 165, 165) !important;
}