.feedback-management {



  .dashboard-header {
    background-color: #055646;
    height: 80px;
    display: flex;
    align-items: center;
  }

  .tr-layout {
    border-radius: 8px !important;
    background: rgba(3, 86, 66, 0.15) !important;
  }

  .done-text {
    color: #212A39;

    text-align: center !important;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 7px !important;
  }

  .header-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 30.17px;
    text-align: left;
    color: white;
    margin-left: 69px;

  }

  .fdbk-dashboard {
    margin-left: 271px;
    /* margin-top: 15px; */
  }

  .sidebar {
    margin: 0px !important;
  }

  .side-text-tab {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    height: 57px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

  }

  .side-text {
    margin-left: 16px;

  }

  .sidebar-hover:hover {
    background: #023a2f;
  }

  .imart-logo {
    display: flex;
    align-items: center;
  }

  .side-bar-tab-view {
    margin-left: 311px;
    margin-top: 40px;
    margin-right: 40px;
  }

  .selected-tab {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: white;
    height: 57px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #022A22;
    border-left: 4px solid white;
    cursor: pointer;

  }

  .preview {

    border-radius: 8px;
    background: rgba(60, 72, 82, 0.15);
    width: 91.5% !important;
    height: 40px;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 18px;

  }

  .icon-size {
    width: 45px;
    height: 45px;
  }

  .preview-section-text {
    color: #3C4852;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .preview-feedback {
    height: '100px';
    background: linear-gradient(-180deg, #006F7E, #40B8B4 90%) !important;

  }

  .edit-del-ques {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    align-items: center;

  }

  .question-preview {

    border-radius: 8px;
    width: 90%;
    // height: 40px;
    flex-shrink: 0;
    margin: 30px;

  }

  .fdbk-scale-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .option-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .main-header-text {
    font-weight: 700px;
    font-size: 24px;
    line-height: 30.17px;
  }

  .btn-text {
    font-size: 18px;
    font-weight: 600;
    margin-left: 32px;
    margin-right: 32px;
    // color: #28a745;
  }

  .btn-text-preview {
    font-size: 18px;
    font-weight: 600;
    margin-left: 32px;
    margin-right: 32px;
    // color: #28a745;
  }

  .save-btn-outline :hover {
    color: white !important;


  }

  // .btn-text :hover{
  //   color: white !important;

  // }

  .save-btn {
    display: flex;
    width: 166px;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: #055646 !important;
  }

  td ul.a {
    list-style-type: circle;
  }
  .save-btn-outline {
    display: flex;
    width: 166px;
    height: 48px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #28a745;

  }

  .empty-text-header {
    color: #3C4852;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .empty-text-desc {
    color: #3C4852;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .section-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 22.63px;
  }

  .sub-header-text {
    color: #212A39;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .select-feedback-form {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
    align-items: center;
  }

  .add-edit-ques {
    width: 90%;
    margin-left: 30px;
    margin-right: 30px;

  }

  .preview-text {
    color: #3C4852;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;


  }

  .add-preview-sec {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-between;
    height: auto !important;  /* Parent height, adjust as needed */

  }

  .section-hover:hover {
    border: 1px solid rgb(29, 29, 29) !important;
  }
  .add-ques-sub-header {
    color: #212A39;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 13px;
    margin-bottom: 8px;
  }

  .search-field {
    max-width: 80%;
    border-radius: 8px;
    border: 1px solid rgba(60, 72, 82, 0.25);
    background: #FFF;
    width: 424px;
    height: 48px;
    flex-shrink: 0;
  }

  .select-course {
    width: 314px;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    background: white !important;
    background-color: white !important;
    // border: 1px solid rgba(60, 72, 82, 0.25);

    background: #FFF;
  }

  .select-scale-section {
    width: 100%;
    height: 48px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid rgba(60, 72, 82, 0.25);

    background: #FFF;
  }

  .selected-fdbk-dropdown {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
  }

  .select-course-create-feeedback {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
  }

  .btn-clr {
    background-color: #035642;
  }

  // .table {
  //   margin-bottom: 10px !important;
  // }

  .resc-btn {
    border-color: #035642 !important;
    margin: 5px !important;
    color: #035642 !important;

  }

  .resc-btn:hover {
    color: white !important;
    background-color: #035642 !important;

  }


  .table-header-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 30.17px;
    text-align: left;
    color: #3C4852;
  }

  .table-cell-text {
    font-size: 16;
    font-weight: 400;
    text-align: left;
    color: #3C4852;
    white-space: nowrap;
  }

  .table-header {
    text-align: center;
    color: #3C4852;
    font-size: 20;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    vertical-align: center;
  }

  .table-cell {
    color: #3C4852;
    font-size: 16;
    // font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    white-space: pre-wrap;

  }

  .hidden {
    display: none;
  }

  .row {
    display: flex;
    justify-content: center;
  }

  .text-center {
    text-align: center;
  }

  .slide-out-x {
    transform: translateX(-100%);
  }

  .slide-out-x-alt {
    transform: translateX(100%);
  }

  .slide-out-y {
    transform: translateY(-100%);
  }

  .slide-out-y-alt {
    transform: translateY(100%);
  }

  .text-violet {
    color: #7f28ff;
  }

  .text-gray {
    color: #4f4f4f;
  }

  .feedback-wrapper .feedback-title {
    padding: 20px;
    color: #fff;
    background: #7f28ff;
    border-radius: 10px 10px 0 0;
    height: 68px;
  }

  .feedback-wrapper .feedback-title h1 {
    margin: 0;
    font-size: 1.4rem;
  }

  .feedback-wrapper .feedback-content {
    overflow-y: auto;
  }

  .feedback-wrapper .feedback-faces {
    // padding: 20px;
    overflow: hidden;
  }

  .face-wrapper {
    position: relative;
    left: 0;
    right: 0;
    width: 60px;
    height: 60px;
    padding: 10px;
    box-sizing: content-box;
    transition: 0.25s ease-out;
  }

  .face-wrapper .face-counter {
    position: absolute;
    right: 0;
    background: #dc230f;
    width: 25%;
    height: 25%;
    text-align: center;
    line-height: 170%;
    font-size: 70%;
    border-radius: 50%;
    font-weight: 800;
    color: #fff;
    z-index: 99;
    box-shadow: inset 0 -1.2px 1.8px #b92413;
    transform: rotate(0deg) scale(1);
    transition: 0.25s ease-out;
  }

  .face-wrapper .face-counter.invisible {
    transform: rotate(150deg) scale(0);
    opacity: 0;
  }

  .face-wrapper .face {
    display: block;
    position: relative;
    background: #ffcd00;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    box-shadow: 0 0.6px 1.2px #cc9117;
    transition: 0.25s ease-out;
  }

  .face-wrapper .face:not([disabled]) {
    cursor: pointer;
  }

  .face-wrapper .face:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    box-shadow: inset 0px -3px 9px #eaa514;
    z-index: 9;
  }

  .face-wrapper .face .eye {
    position: absolute;
    width: 15.6px;
    height: 15.6px;
    margin-top: 32%;
    left: 18%;
    border-radius: 50%;
    transition: 0.25s ease-out;
  }

  .face-wrapper .face .eye:last-of-type {
    right: 18%;
    left: auto;
  }

  .face-wrapper .face .eye .pupil {
    position: absolute;
    background: #000;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
    width: 85%;
    height: 85%;
    border-radius: 50%;
    background: #794014;
    box-shadow: inset 0 -1.2px 0.6px 0px #ca7432;
    transition: width 0.25s ease-out, height 0.25s ease-out;
  }

  .face-wrapper .face .eye .eyelid {
    position: absolute;
    width: 100%;
    height: 0%;
    bottom: -5%;
    border-radius: 50%;
    background: #ffcd27;
    transition: 0.25s ease-out;
  }

  .face-wrapper .face .mouth-wrapper {
    position: absolute;
    top: 60%;
    width: 100%;
  }

  .face-wrapper .face .mouth-wrapper .mouth {
    width: 40%;
    height: 14.4px;
    background: #784015;
    left: 0;
    right: 0;
    margin: auto;
    position: relative;
    border-radius: 290%;
    box-shadow: inset 0 -1.2px 0.6px 0px #ca7432;
    transition: 0.25s ease-out;
  }

  .face-wrapper .face .mouth-wrapper .mouth:before {
    content: "";
    position: absolute;
    width: 120%;
    height: 73%;
    background: #ffcd27;
    border-radius: 0 0 140% 140%;
    top: 0;
    left: -10%;
    transition: 0.25s ease-out;
  }

  .face-wrapper .face.grayscale {
    transform: scale(0.9);
    background: #d3d3d3;
    box-shadow: 0 0.6px 1.2px #ccc;
  }

  .face-wrapper .face.grayscale:after {
    box-shadow: inset 0px -3px 9px #bbbbbb;
  }

  .face-wrapper .face.grayscale .pupil {
    background: #4f4f4f;
    box-shadow: inset 0 -1.2px 0.6px 0px #949494;
  }

  .face-wrapper .face.grayscale .eyelid {
    background: #d3d3d3;
  }

  .face-wrapper .face.grayscale.face-love .eyelid,
  .face-wrapper .face.grayscale.face-love .eyelid:before,
  .face-wrapper .face.grayscale.face-love .eyelid:after {
    background: #707070;
  }

  .face-wrapper .face.grayscale .mouth {
    background: #4f4f4f;
    box-shadow: inset 0 -1.2px 0.6px 0px #949494;
  }

  .face-wrapper .face.grayscale .mouth:before {
    background: #d3d3d3;
  }

  .face-wrapper:hover .face {
    transform: scale(1.1);
  }

  .face-wrapper:hover .eyes-wrapper {
    -webkit-animation: shake infinite 0.15s;
    animation: shake infinite 0.15s;
    transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    perspective: 1000px;
  }

  .face-wrapper:hover .eyes-wrapper .eyelid {
    height: 50%;
  }

  .face-wrapper:hover .mouth-wrapper .mouth {
    transform: scaleX(1.2);
  }

  .face-wrapper:hover .mouth-wrapper .mouth:before {
    transform: translateY(-20%) scaleY(0.75);
  }

  .face-wrapper:active .face {
    transform: scale(1.05);
  }

  .face-wrapper:active .eyes-wrapper .eye .eyelid {
    height: 75%;
  }

  .face-wrapper .face-sad .mouth-wrapper .mouth {
    height: 2.4px;
    top: 8.4px;
    border-radius: 4.2px;
    width: 30%;
  }

  .face-wrapper .face-sad .mouth-wrapper .mouth:before {
    display: none;
  }

  .face-wrapper:hover .face-sad .eyes-wrapper .eye {
    transform: scale(0.9);
  }

  .face-wrapper:hover .face-sad .eyes-wrapper .eyelid {
    height: 0;
  }

  .face-wrapper:hover .face-sad .mouth-wrapper .mouth {
    transform: scaleX(0.9) rotateZ(-10deg);
  }

  .face-wrapper:active .face-sad .eyes-wrapper .eye .pupil {
    top: 0%;
    height: 70%;
  }

  .face-wrapper .face-disappointed .mouth-wrapper {
    transform: rotateZ(-180deg);
  }

  .face-wrapper .face-disappointed .mouth-wrapper .mouth {
    top: -6px;
    height: 9px;
  }

  .face-wrapper .face-disappointed .mouth-wrapper .mouth:before {
    transform: translateY(-30%);
    width: 120%;
    height: 120%;
  }

  .face-wrapper:hover .face-disappointed .eyes-wrapper .eye {
    transform: scale(0.9);
  }

  .face-wrapper:hover .face-disappointed .eyes-wrapper .eyelid {
    height: 0;
  }

  .face-wrapper:hover .face-disappointed .mouth-wrapper .mouth {
    transform: translateY(-5%) scale3d(0.8, 1, 1);
  }

  .face-wrapper:hover .face-disappointed .mouth-wrapper .mouth:before {
    transform: translateY(-30%);
    width: 120%;
    height: 120%;
  }

  .face-wrapper:active .face-disappointed .eyes-wrapper .eye .pupil {
    top: 0%;
    height: 70%;
  }

  .face-wrapper .face-wtf .mouth-wrapper {
    transform: rotateZ(-180deg);
  }

  .face-wrapper .face-wtf .mouth-wrapper .mouth {
    top: -3.6px;
  }

  .face-wrapper:hover .face-disappointed .mouth-wrapper .mouth {
    top: -3.6px;
  }

  .face-wrapper .face-love .eye .pupil {
    background: none;
    box-shadow: none;
  }

  .face-wrapper .face-love .eye .eyelid {
    position: absolute;
    transform: rotate(-45deg);
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background: #dc0e0e;
    border-radius: 0;
    z-index: 999;
    transition: 0.25s ease-out, background 0s;
  }

  .face-wrapper .face-love .eye .eyelid:before,
  .face-wrapper .face-love .eye .eyelid:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #dc0e0e;
    top: -50%;
    border-radius: 50% 50% 0 0;
  }

  .face-wrapper .face-love .eye .eyelid:after {
    top: auto;
    right: -50%;
    border-radius: 0 50% 50% 0;
  }

  .face-wrapper:hover .face-love .eyes-wrapper .eye .eyelid {
    transform: translateY(-5px) rotate(-45deg) scale3d(1.1, 1.1, 1.1);
  }

  .face-wrapper:active .face-love .eyes-wrapper .eye .pupil {
    top: 0%;
    height: 85%;
  }

  .face-wrapper:active .face-love .eyes-wrapper .eye .eyelid {
    height: 12px;
    transform: translateY(-5px) rotate(-45deg) scale3d(1.3, 1.3, 1.3);
  }

  @-webkit-keyframes shake {

    0%,
    100% {
      transform: translate3d(0, 0, 0);
    }

    25% {
      transform: translate3d(1%, 1%, 0);
    }

    75% {
      transform: translate3d(1%, 1%, 0);
    }
  }

  @keyframes shake {

    0%,
    100% {
      transform: translate3d(0, 0, 0);
    }

    25% {
      transform: translate3d(1%, 1%, 0);
    }

    75% {
      transform: translate3d(1%, 1%, 0);
    }
  }


  .custom-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* Hide default thumb appearance */
    appearance: none;
    /* Hide default thumb appearance */
    width: 40px;
    /* Adjust thumb width as needed */
    height: 45px;
    /* Adjust thumb height as needed */
    background-image: url('https://cdn.pegasus.imarticus.org/feedback/SliderIcon.svg');
    /* Replace with the actual path to your image */

    cursor: pointer;
    /* Change cursor to pointer */
  }
}