/* LoadingOverlay.css */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.612);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it overlays everything */
}

.spinner-container {
  position: relative;
}

.spinner {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #014644;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: spin 1s linear infinite;
}

.brand-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85px; /* Adjust size as needed */
  height: 85px; /* Adjust size as needed */
  border-radius: 80px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
