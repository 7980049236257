$tiny: 300px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extraLarge: 1200px;

.applyContainer {
  background: linear-gradient(245.09deg, #178990 2.97%, #0d4b6f 100%);
  opacity: 0.9;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Source Sans Pro';
  font-style: normal;
}

.error-container {
  border: 1px solid transparent;
  border-color: #ebccd1;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

  .error-header {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;

    .error-title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 18px;
      color: inherit;
    }
  }

  .error-list {
    margin-top: 12px;
    .error-tile {
      color: #a94442;
    }
  }
}

.course {
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.5);
  border-radius: 8px;
  width: 70%;
  margin: 16px;
  margin-top: 96px;

  .courseTitle1 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #3c4852;
    opacity: 0.5;
    text-transform: uppercase;
    margin: 12px;
  }

  .courseTitle2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #3c4852;
    text-transform: uppercase;
    margin: 12px;
  }

  .courseDesc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #3c4852;

    margin: 12px;
  }

  @media screen and (max-width: $extraLarge) {
    width: 80%;
  }

  @media screen and (max-width: $large) {
    width: 90%;
  }

  @media screen and (max-width: $medium) {
    width: calc(100% - 80px);
  }

  @media screen and (max-width: $small) {
    width: calc(100% - 32px);
  }
}

.popup-container {
  position: fixed;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: rgba(33, 42, 57, 0.75);
  backdrop-filter: blur(18px);
  display: flex;
  justify-content: center;
  align-items: center;

  .popup {
    background: #ffffff;
    border-radius: 8px;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 24px;

    .status {
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      color: #3c4852;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }

    .status-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #3c4852;
    }

    .status-button {
      background: #035642;
      border-radius: 8px;
      color: #ffffff;
      border: none;
      margin: 12px;
      padding: 8px 24px;
    }

    @media screen and (max-width: $extraLarge) {
      width: 80%;
    }

    @media screen and (max-width: $large) {
      width: 90%;
    }

    @media screen and (max-width: $medium) {
      width: calc(100% - 80px);
    }

    @media screen and (max-width: $small) {
      width: calc(100% - 32px);
      padding: 8px;
    }
  }

  .popup-small {
    background: #ffffff;
    border-radius: 8px;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    @media (max-width: 450px) {
      width: 95vw;
    }
  }
}

.stages {
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.5);
  border-radius: 8px;
  width: 70%;

  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 16px;
  padding: 12px;

  @media screen and (max-width: $extraLarge) {
    width: 80%;
  }

  @media screen and (max-width: $large) {
    width: 90%;
  }

  @media screen and (max-width: $medium) {
    display: none;
  }

  @media screen and (max-width: $small) {
    display: none;
  }

  .stage {
    background: #def1f6;
    width: 100%;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .stageName {
      color: #3c4852;
      padding: 12px 16px 12px 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      z-index: 2;

      .stageText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .stageIcon {
        margin-right: 4px;
      }
    }

    .arrow {
      background-color: #cde8f0;
      height: 3rem;
      transform: scale(0.5) rotate(45deg) translate(-36px, 36px);
      aspect-ratio: 1;
      z-index: 1;
      border-radius: 4px;
      position: absolute;
    }

    &:nth-of-type(even) {
      background: #cde8f0;

      .arrow {
        background: #def1f6;
      }
    }
  }

  // .stage:hover {
  //   .stageName {
  //     //transform: scale(1.1) translateX(4px);
  //   }
  // }

  .current-stage {
    background: #a8e2f1 !important;
    font-weight: 700;
  }

  .next-stage {
    .arrow {
      background: #a8e2f1 !important;
    }

    .disabled-arrow {
      background: #a8e2f1 !important;
    }
  }

  .disabled {
    background: #e7e9ea;
    cursor: default;

    .arrow {
      background: #f2f3f4;
    }

    &:nth-of-type(even) {
      background: #f2f3f4;

      .arrow {
        background: #e7e9ea;
      }
    }
  }

  .disabled:hover {
    .stageName {
      transform: none;
    }
  }

  .disabled-arrow {
    background: #e7e9ea;
    cursor: default;

    &:nth-of-type(even) {
      background: #f2f3f4;
    }
  }

  .disabled-arrow:hover {
    .stageName {
      transform: none;
    }
  }
}

.stages-perc {
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.5);
  border-radius: 8px;
  width: 70%;

  display: none;
  justify-content: space-evenly;
  align-items: center;
  margin: 16px;
  padding: 12px;

  @media screen and (max-width: $extraLarge) {
    width: 80%;
  }

  @media screen and (max-width: $large) {
    width: 90%;
  }

  @media screen and (max-width: $medium) {
    display: flex;
    width: calc(100% - 80px);
    flex-direction: column;
    margin: 8px;
  }

  @media screen and (max-width: $small) {
    display: flex;
    width: calc(100% - 32px);
    flex-direction: column;
  }

  .perc-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 12px 0;

    .perc-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #3c4852;
    }

    .perc {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: rgba(60, 72, 82, 0.8);
    }
  }

  .perc-bar {
    width: 100%;
    height: 12px;
    background: #dcedf2;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    margin: 12px 0;

    .perc-complete {
      background: #1688a8;
      border-radius: 8px;
      height: 100%;
    }
  }
}

.formContainer {
  background: #ffffff;
  border: 1px solid rgba(60, 72, 82, 0.5);
  border-radius: 8px;
  width: 70%;

  margin: 16px;

  @media screen and (max-width: $extraLarge) {
    width: 80%;
  }

  @media screen and (max-width: $large) {
    width: 90%;
  }

  @media screen and (max-width: $medium) {
    width: calc(100% - 80px);
  }

  @media screen and (max-width: $small) {
    width: calc(100% - 32px);
  }

  .rjsfForm {
    padding: 24px;

    @media screen and (max-width: $small) {
      padding: 6px;
    }
  }

  .formCtrl {
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(60, 72, 82, 0.25);

    @media screen and (max-width: $tiny) {
      padding: 4px 12px;
    }

    .prev {
      background: #ffffff;
      border: 1px solid rgba(60, 72, 82, 0.5);
      border-radius: 8px;
      color: #035642;
      padding: 8px 24px;

      @media screen and (max-width: $tiny) {
        padding: 4px 12px;
      }
    }

    .stageCount {
      color: #3c4852;
      font-weight: 600;
      font-size: 16px;
      line-height: 56px;
    }

    .next {
      background: #035642;
      border-radius: 8px;
      color: #ffffff;
      border: none;
      padding: 8px 24px;

      @media screen and (max-width: $tiny) {
        padding: 4px 12px;
      }
    }
  }
}

.blueLine {
  background: #47c3cf;
  border-radius: 8px;
  height: 8px;
  width: 100%;
}

.admissionTestContainer {
  padding: 56px 80px 40px;

  @media screen and (max-width: $extraLarge) {
    padding: 48px 64px 32px;
  }

  @media screen and (max-width: $large) {
    padding: 32px 56px 28px;
  }

  @media screen and (max-width: $medium) {
    padding: 24px 40px 20px;
  }

  @media screen and (max-width: $small) {
    padding: 16px;
  }

  .admissionTestContainerImage {
    display: block;
    @media screen and (max-width: $medium) {
      display: none;
    }
  }

  .admissionTestContainerImageMb {
    display: none;
    @media screen and (max-width: $medium) {
      display: block;
    }
  }

  .admissionTest-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding: 40px 0 32px;
    @media screen and (max-width: $medium) {
      font-size: 18px;
      line-height: 24px;
      padding: 16px 0 8px;
    }
  }

  .admission-testname-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 76px;

    @media screen and (max-width: $medium) {
      height: 52px;
    }

    .admission-testname {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      @media screen and (max-width: $medium) {
        line-height: 28px;
        font-size: 14px;
      }
    }

    .button-group {
      display: flex;
      align-items: center;

      .status-img {
        margin: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(180deg);
      }

      .arrow-img {
        margin: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .admission-test-start-btn {
      background: #035642;
      border: none;
      border-radius: 8px;
      font-weight: 600;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
      padding: 16px 32px;

      @media screen and (max-width: $medium) {
        display: none;
      }
    }

    .admission-test-pending-state {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      @media screen and (max-width: $medium) {
        line-height: 28px;
        font-size: 14px;
      }
    }
  }

  .admission-test-instruction-container {
    padding: 8px 0px 20px;

    .admission-test-instruction-header {
      font-weight: 600;
      font-size: 18px;
      line-height: 16px;
      padding-bottom: 16px;
      @media screen and (max-width: $medium) {
        line-height: 16px;
        font-size: 18px;
      }
    }

    .admission-test-instruction {
      font-weight: 400;
      font-size: 18px;
      line-height: 16px;
      @media screen and (max-width: $medium) {
        line-height: 16px;
        font-size: 14px;
      }
    }
  }

  .admission-testname-container:hover {
    cursor: pointer;
  }

  .admission-testname-border {
    border-bottom: 1px solid rgba(60, 72, 82, 0.15);
  }

  .admission-test-start-btn {
    background: #035642;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    color: #ffffff;
    padding: 16px 32px;

    @media screen and (max-width: $medium) {
      display: none;
    }
  }

  .admission-test-start-btn-mb {
    background: #035642;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
    padding: 14px 24px;
    margin-bottom: 20px;

    @media screen and (min-width: $medium) {
      display: none;
    }
  }
}
